<template>
  <v-app>
    <!-- APP Top Navigation -->
    <v-app-bar v-if="$route.path !== '/session'" app id="topOfPage" color="background" elevate-on-scroll
               style="box-shadow: 0 1px 4px 0 var(--v-border-base); box-sizing: border-box; z-index: 20">
      <v-row justify="center" v-if="store.app.onDesktop || ($route.path !== '/home' && $vuetify.breakpoint.mdAndUp)" class="py-2">
        <v-col :class="$route.path === '/home' ? 'navCol' : 'ma-0 px-2'">
          <v-row class="navRow px-6">
            <v-row v-if="$route.path === '/home'" class="ma-0 pa-0 align-center">
              <v-icon color="accent" class="mr-3 pb-1" x-large @click="navigateTo('/', 1)">mdi-book-open-page-variant</v-icon>
              <h1 class="heading mr-10" @click="navigateTo('/', 1)">UofT Index</h1>
              <router-link v-for="page in pages[0].options" :key="page.link" :to="page.link" class="mx-5 routerLink">
                {{ page.page }}
                <v-chip v-if="page.beta" small color="utm" class="px-2 ml-1 font-weight-bold">BETA</v-chip>
              </router-link>
            </v-row>
            <v-breadcrumbs v-else class="px-2" :items="store.app.pageCrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item @click="$router.push(item.href)" :disabled="disableCrumb(item)" class="text-capitalize">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-spacer/>
            <v-chip v-if="!['/session', '/home'].includes($route.path) && store.app.onDesktop" label
                    class="px-4 mr-6 text--secondary rounded" color="border" @click="store.app.commandModal = true">
              Command Panel
              <span class="ml-3 px-2 py-0" style="border: 1px solid var(--v-tooltip-base); border-radius: 5px">
                <v-icon x-small>mdi-apple-keyboard-command</v-icon>/Ctrl + K
              </span>
            </v-chip>
            <v-menu v-if="store.user.userInfo" transition="slide-y-transition" offset-y bottom left nudge-right="20">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :ripple="false" depressed fab text color="transparent" class="mr-6" style="position: relative">
                  <Avatar :showBg="true" size="36" v-on="on" />
                  <v-icon color="text" style="position: absolute; right: -20px">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, idx) in dropDown" :key="idx" @click="executeOptions(item.page)">
                  <v-icon color="accent" class="mr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title :data-cy="item.option">{{ item.option }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div v-else class="flex-row">
              <v-btn depressed @click="createSession('LgEmail')" color="transparent" id="login_signup"
                     class="font-weight-medium px-3 mx-1 text-capitalize text-body-1">Log in</v-btn>
              <v-btn @click="createSession('SuEmail')" color="accent" depressed id="login_signup"
                     class="font-weight-medium px-4 mx-3 text-capitalize text-body-1">Sign up</v-btn>
            </div>
            <v-divider vertical class="ml-2 mr-4 my-3"/>
            <v-menu transition="slide-y-transition" offset-y left bottom rounded close-on-click nudge-right="10" nudge-bottom="15">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="accent" class="pointer">mdi-cog-outline</v-icon>
              </template>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" color="accent" class="pointer">mdi-cog-outline</v-icon>
                  </template>
                  <span>App Settings</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item>
                  <v-switch v-model="store.app.darkMode" color="accent" dense inset hide-details label="Dark Mode"/>
                </v-list-item>
                <v-list-item>
                  <v-switch v-model="store.app.liveAnimations" color="accent" dense inset label="Live Animations"/>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else-if="$route.path === '/home' || $vuetify.breakpoint.smAndDown" align="center" class="py-3">
        <v-btn depressed class="px-0 ml-0 ml-sm-4 mt-1" color="transparent" icon @click="showDropdown = !showDropdown">
          <v-icon color="text" size="28">mdi-menu</v-icon>
        </v-btn>
        <v-icon v-if="$vuetify.breakpoint.mdAndUp" color="accent" class="ml-3 py-1" x-large
                @click="navigateTo('/', 1)">mdi-book-open-page-variant</v-icon>
        <h1 class="heading ml-1 pt-1 align-self-center" @click="navigateTo('/', 1)">UofT Index</h1>
      </v-row>
    </v-app-bar>

    <!-- APP Sidebar -->
    <v-navigation-drawer v-if="!['/session', '/home'].includes($route.path) && $vuetify.breakpoint.mdAndUp" permanent app color="#002554"
                         :key="showDropdown" :mini-variant="collapsed" floating
                         mini-variant-width="65" class="px-2" width="240" @transitionend="sidebarTransition($event)">
      <template v-slot:prepend>
        <v-app-bar v-if="$route.path !== '/session'" height="64" flat fixed elevation="0" @click="navigateTo('/', 1)"
                   style="background-color: #002554; border-right: 1px solid #002554">
          <v-col class="ma-0 pa-0">
            <v-row class="ma-0 py-0">
              <v-icon size="28" color="#50ADFF" class="ml-1 mr-2">mdi-book-open-page-variant</v-icon>
              <h1 v-if="!collapsed" class="heading mt-1 white--text">UofT Index</h1>
            </v-row>
          </v-col>
        </v-app-bar>
      </template>
      <v-list v-for="(section, idx) in pages" :key="idx" :style="idx < 1 ? 'margin-top: 69px;' : ''">
        <v-subheader v-if="section.heading.length && !collapsed" class="font-weight-bold grey--text text-uppercase">{{ section.heading }}</v-subheader>
        <v-list-item v-for="(page, pageIdx) in section.options" :key="pageIdx" link @click="navigateTo(page.link, page.internal)"
                     :class="$route.path === page.link ? 'activeSideBarItem my-1' : 'sideBarItem my-1'">
          <v-list-item-icon style="margin-top: 9px; margin-bottom: 0">
            <v-icon v-if="!collapsed" :color="$route.path === page.link ? '#50ADFF' : 'white'">{{ page.icon }}</v-icon>
            <v-tooltip v-else right color="tooltip" style="z-index: 100">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="$route.path === page.link ? '#50ADFF' : 'white'">{{ page.icon }}</v-icon>
              </template>
              <span>{{ page.page }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text font-weight-medium" style="font-size: 0.9rem">
              {{ page.page }}
              <v-chip v-if="page.new" small color="warning" class="ml-3 font-weight-bold">NEW</v-chip>
              <v-chip v-if="page.beta" small color="utm" class="ml-3 font-weight-bold">BETA</v-chip>
              <v-icon v-if="!page.internal" size="14" color="#FFFFFF">mdi-open-in-new</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="idx + 1 < pages.length" class="mt-4 mb-2" style="border-color: #2C4580"/>
      </v-list>
      <template v-slot:append>
        <v-row justify="end" class="py-8" style="margin-right: 10px">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="28" color="#fff" v-bind="attrs" v-on="on" class="text-right e-hover"
                      @click="collapsed = !collapsed">
                {{ collapsed ? 'mdi-page-last' : 'mdi-page-first' }}
              </v-icon>
            </template>
            <span>{{ collapsed ? 'Show Sidebar' : 'Hide Sidebar' }}</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-navigation-drawer>

    <!-- MOBILE Navigation Drawer -->
    <v-dialog v-model="showDropdown" transition="slide-x-transition" fullscreen>
      <v-card class="d-flex flex-column overflow-x-hidden">
        <v-card-title class="pa-0 absolute w-full" style="top: 0">
          <v-row class="ma-0 px-4 py-2 align-center border">
            <v-icon large color="accent">mdi-book-open-page-variant</v-icon>
            <v-spacer/>
            <v-icon @click="showDropdown = !showDropdown">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0 mobileNavSection">
          <v-list>
            <v-list-item v-for="(page, idx) in pages[0].options" :key="idx" link @click="navigateTo(page.link, 1)">
              <v-list-item-icon><v-icon color="secondary" class="ml-1">{{ page.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ page.page }}
                  <v-chip v-if="page.beta" small color="utm" class="ml-3 font-weight-bold">BETA</v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider class="mx-4"/>
          <v-list>
            <v-list-group v-for="(section, secIDX) in pages.slice(1, 3)" :key="secIDX" no-action
                          active-class="border accent--text" v-model="mobileListGroup[secIDX]">
              <template v-slot:activator>
                <v-list-item-icon><v-icon color="secondary" class="ml-1">{{ section.icon }}</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ section.heading }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="subsection in section.options" :key="subsection.page" link @click="navigateTo(subsection.link, subsection.internal)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subsection.page }}
                    <v-icon v-if="!subsection.internal" size="14" class="ml-1" color="text">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-divider class="mx-4"/>
          <v-list v-if="store.user.userInfo">
            <v-list-item link @click="navigateTo('/dashboard', 1)">
              <v-list-item-icon><v-icon color="secondary" class="ml-1">mdi-view-dashboard</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>MyDashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item v-if="store.user.userInfo" link @click="logout">
              <v-list-item-icon><v-icon color="secondary" class="ml-1">mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else link class="my-1" @click="navigateTo('/session', 1)">
              <v-list-item-icon><v-icon color="secondary" class="ml-1">mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Log in | Sign up</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider class="mx-4"/>
          <v-row class="py-4 ml-5" style="padding-bottom: 150px !important;">
            <v-switch v-model="store.app.darkMode" inset label="Dark Mode" color="blue darken-3" class="font-weight-medium"/>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-main style="overflow-x: hidden">
      <span v-if="$vuetify.breakpoint.smAndDown && !['/session', '/home'].includes($route.path)">
        <v-divider/>
        <v-breadcrumbs class="px-4 px-sm-8 py-3" :items="store.app.pageCrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item @click="$router.push(item.href)" :disabled="disableCrumb(item)" class="text-capitalize">
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </span>
      <router-view/>
    </v-main>

    <!-- MODAL - Command panel -->
    <CommandModal/>
    <!-- FAB - Timetable cart -->
    <TimetableCart :current-position="scrollPosition" :threshold="500"/>
    <!-- FAB - Scroll up btn when scrolled passed a certain threshold -->
    <ScrollUp v-if="store.app.customFABPath.indexOf(this.$route.path) < 0 || store.data.timetableCourses.length < 1 || store.app.onMobile"
              :current-position="scrollPosition" :threshold="500" @scrollUp="$scrollTo('#topOfPage', { duration: 350 })"/>

    <v-footer v-if="$route.path === '/home'" class="footerBar pt-4 pb-1">
      <v-col class="my-2 contentMaxWidth">
        <v-row>
          <v-col class="pt-0 px-0 footerCol">
            <v-icon class="footerIcon" color="accent">mdi-book-open-page-variant</v-icon>
            <p class="heading pt-1 mb-0 accent--text">UofT Index</p>
          </v-col>
          <v-col v-for="idx in [1, 0, 2]" :key="idx" lg="3" md="3" xs="12" class="mb-3 px-0 footerCol">
            <p class="grey--text text--darken-1 text-body-2 font-weight-bold text-uppercase">{{ pages[idx].heading || 'QUICK LINKS' }}</p>
            <v-row v-for="(option, index) in pages[idx].options" :key="index" class="px-3 my-0">
              <p class="text--primary text-body-2 mb-3 pointer" @click="navigateTo(option.link, option.internal)">{{ option.page }}</p>
              <v-icon v-if="!option.internal" size="12" class="mb-2 ml-1" color="text">mdi-open-in-new</v-icon>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="px-0"><v-divider class="my-2 px-0"/></v-row>
        <v-row class="mt-4 pa-1 align-center">
          <p class="text--primary text-body-2 mb-0 mr-4">Copyright © {{ new Date().getFullYear() }} UofT Index. All Rights Reserved.</p>
          <v-spacer v-if="currentWidth > 1385"/>
          <v-btn href="https://github.com/uoftindex" target="_blank" class="mr-2" height="24" width="24" icon>
            <v-icon color="#707070">mdi-github</v-icon>
          </v-btn>
          <v-btn href="mailto:uoftindex@outlook.com" class="pa-0" height="24" width="24" icon>
            <v-icon color="#707070">mdi-email-outline</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-footer>
    <v-footer v-else-if="$route.path !== '/session'" class="pa-4" color="background" :style="store.app.onMobile ? '' : collapsed ?  'margin-left: 65px' : 'margin-left: 240px'">
      <v-col class="my-2">
        <v-row class="px-0"><v-divider class="my-2 px-0"/></v-row>
        <v-row class="mt-7 px-1 align-center">
          <p class="text--primary text-body-2 mb-0 mr-4">Copyright © {{ new Date().getFullYear() }} UofT Index.
            <span v-if="!store.app.onMobile">All Rights Reserved.</span>
          </p>
          <v-btn href="https://github.com/uoftindex" target="_blank" class="mr-2" height="24" width="24" icon>
            <v-icon color="#707070">mdi-github</v-icon>
          </v-btn>
          <v-btn href="mailto:uoftindex@outlook.com" class="pa-0" height="24" width="24" icon>
            <v-icon color="#707070">mdi-email-outline</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>

import TimetableCart from '@/components/timetable/TimetableCart'
import ScrollUp from '@/components/ScrollUp'
import { getAuth } from 'firebase/auth'
import Avatar from './components/shared/Avatar.vue'
import CommandModal from '@/components/CommandModal'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'App',
  components: { CommandModal, TimetableCart, ScrollUp, Avatar },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    showDropdown: false,
    mobileListGroup: [false, false],
    activeKeys: {},
    scrollPosition: 0,
    currentWidth: 0,
    collapsed: false,
    pages: [
      {
        heading: '',
        icon: '',
        options: [
          { page: 'Directory', icon: 'mdi-database-search', link: '/directory', internal: 1, new: false, beta: false },
          { page: 'Timetable', icon: 'mdi-calendar', link: '/timetable', internal: 1, new: false, beta: false },
          { page: 'Planner', icon: 'mdi-trello', link: '/plan', internal: 1, new: false, beta: false },
          { page: 'Course Tree', icon: 'mdi-sitemap', link: '/tree', internal: 1, new: false, beta: false },
          { page: 'Drop Rates', icon: 'mdi-finance', link: '/droprate', internal: 1, new: false, beta: false },
          { page: 'Compare', icon: 'mdi-scale-unbalanced', link: '/compare', internal: 1, new: false, beta: false }
        ]
      },
      {
        heading: 'Company',
        icon: 'mdi-home-city-outline',
        options: [
          { page: 'About', icon: 'mdi-information-variant', link: '/about', internal: 1, new: false, beta: false },
          { page: 'Feedback & Report', icon: 'mdi-forum', link: '/feedback', internal: 1, new: false, beta: false },
          { page: 'Roadmap', icon: 'mdi-map-search-outline', link: 'https://uoftindex.canny.io/', internal: 0, new: false, beta: false },
          { page: 'Changelog', icon: 'mdi-format-list-text', link: '/changelog', internal: 1, new: false, beta: false }
        ]
      },
      {
        heading: 'Resources',
        icon: 'mdi-package-variant',
        options: [
          { page: 'Privacy Policy', icon: 'mdi-shield-account', link: '/privacy', internal: 1, new: false, beta: false },
          { page: 'Terms of Use', icon: 'mdi-file-sign', link: '/terms', internal: 1, new: false, beta: false },
          { page: 'Disclaimers', icon: 'mdi-clipboard-text', link: '/disclaimers', internal: 1, new: false, beta: false }
        ]
      }
    ],
    dropDown: [
      { option: 'MyDashboard', icon: 'mdi-view-dashboard', page: '/dashboard' },
      { option: 'Logout', icon: 'mdi-logout', page: 'logout' }
    ]
  }),
  mounted () {
    // Check dark mode
    if (localStorage.getItem('dark-mode') === null) {
      this.store.app.darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    } else {
      this.store.app.darkMode = localStorage.getItem('dark-mode') === 'true'
    }
    // Check live animations
    if (localStorage.getItem('live-animations') === null) {
      this.store.app.liveAnimations = true
    } else {
      this.store.app.liveAnimations = localStorage.getItem('live-animations') === 'true'
    }
    document.addEventListener('keydown', this.onDocumentKeydown)
    window.addEventListener('scroll', this.updateScroll)
    // Window width data
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      this.getWindowWidth()
    })

    // Fetch course list used for all pages
    this.loadCampusCourses()

    // Check for timetable cart
    if (localStorage.getItem('timetable-cart') !== null) {
      this.store.data.setTimetableCart(JSON.parse(localStorage.getItem('timetable-cart')))
    }

    this.collapsed = this.$vuetify.breakpoint.mdAndDown
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('scroll', this.updateScroll)
  },
  watch: {
    'store.app.darkMode': function (newValue) {
      this.$vuetify.theme.dark = newValue
      localStorage.setItem('dark-mode', newValue)
      this.$gtag.event('dark_mode_' + newValue, { value: 1 })
    },
    'store.app.liveAnimations': function (newValue) {
      localStorage.setItem('live-animations', newValue)
      this.$gtag.event('live_animations_' + newValue, { value: 1 })
    }
  },
  methods: {
    sidebarTransition (e) {
      this.collapsed = e.target._prevClass.indexOf('v-navigation-drawer--mini-variant') > -1
    },
    getWindowWidth () {
      this.currentWidth = document.documentElement.clientWidth
      this.store.app.onMobile = this.currentWidth <= 600
      this.store.app.onDesktop = this.currentWidth >= 1200
    },
    logout () {
      getAuth().signOut().then(() => {
        this.closeSession()
        this.showDropdown = false
      })
    },
    async loadCampusCourses () {
      ['UTSC', 'UTSG', 'UTM'].forEach((campus) => this.store.data.loadCourseList(campus))
    },
    async closeSession () {
      const q = { query: 'mutation endSession { endSession { completed } }', operationName: 'endSession' }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data.endSession.completed) {
            this.store.user.setUserState(null)
            if (this.$route.path !== '/home') {
              this.$router.push('/home')
            } else {
              location.reload()
            }
          }
        })
        .catch((err) => console.error(err))
    },
    createSession (page) {
      this.store.app.defaultSessionPage = page
      this.$router.push('/session')
    },
    executeOptions (option) {
      // Determine if navigating to new page or logging out
      if (option !== 'logout') {
        if (this.$route.path !== '/dashboard') this.$router.push(option)
      } else {
        this.logout()
      }
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    onDocumentKeydown (e) {
      if (!this.store.app.commandModal && [224, 17, 75].includes(e.keyCode)) {
        this.activeKeys[e.keyCode] = true
        const keys = Object.keys(this.activeKeys)
        if ((keys.includes('224') || keys.includes('17')) && keys.includes('75')) {
          e.preventDefault()
          this.store.app.commandModal = true
          this.activeKeys = {}
          this.$gtag.event('command_panel_keyboard_activate', { value: 1 })
        }
      } else if (this.store.app.commandModal && e.keyCode === 27) {
        this.store.app.commandModal = false
      } else {
        this.activeKeys = {}
      }
    },
    navigateTo (link, internal) {
      this.showDropdown = false
      setTimeout(() => { this.mobileListGroup = [false, false] }, 1000)
      if (internal) {
        this.$router.push(link)
      } else {
        window.open(link, '_blank').focus()
      }
    },
    disableCrumb (crumb) {
      return this.store.app.pageCrumbs.findIndex(entry => entry.href === crumb.href) === (this.store.app.pageCrumbs.length - 1)
    }
  }
}
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Jost:wght@700&display=swap');

  p, li {
    color: var(--v-text-base);
  }
  .v-main {
    background: var(--v-background-base) !important;
  }
  .mobileNavSection {
    margin-top: 52px;
    max-height: calc(100vh - 52px);
    overflow-x: hidden;
    overflow-y: scroll
  }
  .navRow {
    justify-content: center;
    align-items: center;
  }
  .navCol { /* Do not remove */
    max-width: 1385px !important;
    justify-content: center;
    align-items: center;
  }
  .sideNav {
    z-index: 12 !important;
  }
  .heading {
    font-family: 'Jost', sans-serif;
    font-size: 25px;
  }
  .heading:hover {
    cursor: pointer;
  }
  .routerLink {
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    color: var(--v-text-base) !important;
  }
  .sideBarItem {
    min-height: 43px;
  }
  .sideBarItem:hover { /* Do not remove */
    background-color: rgba(145, 136, 136, 0.25);
    border-radius: 6px;
  }
  .activeSideBarItem { /* Do not remove */
    background-color: rgba(145, 136, 136, 0.25);
    border-radius: 6px;
    min-height: 43px;
  }
  .footerBar {
    justify-content: center;
    background-color: #F3F4F6;
    border: 1px solid #E6E6E6;
    padding: 0 20px !important;
  }
  .footerCol {
    min-width: 265px;
  }
  .footerIcon {
    font-size: 75px !important;
  }

</style>
